// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import { format } from 'date-fns';
import NavContent from '../blocks/navigation/NavContent';
import YearCalendarContent from '../blocks/year_calendar/YearCalendarContent';
import ReduxModal from '../blocks/modal/ReduxModal';
import CalendarTimeLogModal from '../blocks/calendar/CalendarTimeLogModal';
import { formatDateToISOString } from '../../helpers/dates';
import { LOG_ADD_MODAL, LOG_EDIT_MODAL } from '../../redux/reducers/modal';
import type {
  Log, User, Project, Holiday,
} from '../../types';

type State = {
  modal : {
      date: any,
      edit: boolean,
      title: string,
      saveBtnText: string,
      log?: ?Log,
  },
}

type Props = {
    logsById: Object,
    datesWithLogs: Array<string>,
    dateTotals: {[date: string]: number},
    currentUser: User,
    users: Array<User>,
    projects: Array<Project>,
    categories: Array<Object>,
    selectedUser: User,
    routeParams: Object,
    navigate: Object,
    location: Object,
    onAddLog: Function,
    onRemoveLog: Function,
    onUpdateLog: Function,
    onSelectedUserChange: Function,
    onOpenModal: Function,
    onCloseModal: Function,
    onUpdateModal: Function,
    onSelectedDayChange: Function,
    onRequestDatesWithLogs: Function,
    holidays: Array<Holiday>,
    vacations: Object,
}

class YearCalendar extends Component<Props, State> {
  state = {
    modal: {
      date: null,
      edit: false,
      saveBtnText: '',
      title: '',
      log: null,
    },
  };

  componentDidMount = () => {
    const {
      routeParams: { date },
      selectedUser: { id: selectedUserId },
      onSelectedDayChange,
      onRequestDatesWithLogs,
    } = this.props;
    const selectedYear = date ? date.split('-')[0] : _.toString(new Date().getFullYear());
    const selectedDate = date || formatDateToISOString(new Date());
    onRequestDatesWithLogs(selectedYear, selectedUserId);
    onSelectedDayChange(selectedDate, selectedUserId);
  };

  componentDidUpdate = (prevProps: Props) => {
    const {
      routeParams: { date },
      selectedUser: { id: selectedUserId },
      onSelectedDayChange,
      onRequestDatesWithLogs,
    } = this.props;
    const dateChanged = prevProps.routeParams.date !== date;
    const selectedYear = date ? date.split('-')[0] : _.toString(new Date().getFullYear());
    const selectedDate = date || formatDateToISOString(new Date());
    if (dateChanged) {
      onRequestDatesWithLogs(selectedYear, selectedUserId);
      onSelectedDayChange(selectedDate, selectedUserId);
    }
  };

  getLogModalId = () => {
    const { modal: { edit } } = this.state;

    return edit ? LOG_EDIT_MODAL : LOG_ADD_MODAL;
  };

  handleOpenAddModal = (date: string) => {
    const { onOpenModal } = this.props;
    onOpenModal({ id: LOG_ADD_MODAL });
    this.setState({
      modal: {
        date,
        title: 'Add a log',
        saveBtnText: 'Save new log',
        edit: false,
        log: null,
      },
    });
  };

  handleOpenEditModal = (log: Log, date: string) => {
    const { onOpenModal } = this.props;
    onOpenModal({ id: LOG_EDIT_MODAL });
    this.setState({
      modal: {
        date,
        title: 'Edit log',
        saveBtnText: 'Save',
        edit: true,
        log,
      },
    });
  };

  handleAddModalClose = () => this.handleModalClose(LOG_ADD_MODAL);

  handleEditModalClose = () => this.handleModalClose(LOG_EDIT_MODAL);

  handleModalClose = (id: string) => {
    const { onCloseModal } = this.props;
    onCloseModal({ id });
    this.setState({
      modal: {
        date: null,
        edit: false,
        saveBtnText: '',
        title: '',
        log: null,
      },
    });
  };

  handleSubmitLogForm = (formData: Object) => {
    const { modal: { date, edit } } = this.state;
    const { onAddLog, onUpdateLog, selectedUser } = this.props;
    this.disableFormControls(edit ? LOG_EDIT_MODAL : LOG_ADD_MODAL);
    formData.date = date;
    formData.user_id = selectedUser.id;
    formData.user = selectedUser;
    if (!edit) {
      onAddLog(formData.project_id, formData).then(this.handleAddModalClose);
    } else {
      onUpdateLog(formData.project_id, formData.id, formData)
        .then(this.handleEditModalClose);
    }
  };

  handleDeleteLog = (log: Log) => {
    const { onRemoveLog } = this.props;
    this.disableFormControls(LOG_EDIT_MODAL);
    onRemoveLog(log.project_id, log.id).then(this.handleEditModalClose);
  };

  disableFormControls = (id: string) => {
    const { onUpdateModal } = this.props;
    onUpdateModal({ id, disableButtons: true });
  };

  handleNavTodayClick = (toWeekCalendar: string) => {
    const { navigate } = this.props;
    const today = format(new Date(), 'yyyy-MM-dd');
    if (toWeekCalendar === 'to-week-calendar') {
      navigate('/');
    } else if (toWeekCalendar === 'to-today') {
      navigate(`/year-calendar/${today}`);
    }
  };

  handleNavPrevYearClick = () => {
    const { navigate, routeParams: { date } } = this.props;
    const previouslySelectedYear = date ? date.split('-')[0] : _.toString(new Date().getFullYear());
    if (previouslySelectedYear === _.toString(new Date().getFullYear() + 1)) {
      navigate('/year-calendar');
    } else {
      navigate(`/year-calendar/${_.toInteger(previouslySelectedYear) - 1}-01-01`);
    }
  };

  handleNavNextYearClick = () => {
    const { navigate, routeParams: { date } } = this.props;
    const previouslySelectedYear = date ? date.split('-')[0] : _.toString(new Date().getFullYear());
    if (previouslySelectedYear === _.toString(new Date().getFullYear() - 1)) {
      navigate('/year-calendar');
    } else {
      navigate(`/year-calendar/${_.toInteger(previouslySelectedYear) + 1}-01-01`);
    }
  };

  handleSelectedUserChange = (user: User) => {
    const {
      routeParams, onSelectedUserChange, onRequestDatesWithLogs, onSelectedDayChange,
    } = this.props;
    const date = routeParams.date || formatDateToISOString(new Date());
    if (user) {
      onSelectedUserChange(user).then(() => {
        onRequestDatesWithLogs(date.split('-')[0], user.id);
        onSelectedDayChange(date, user.id);
      });
    }
  };

  handleDateSelect = (event: Object) => {
    const { navigate } = this.props;
    const selectedDate = formatDateToISOString(new Date(event.date));
    navigate(`/year-calendar/${selectedDate}`);
  };

  render(): Node {
    const { modal } = this.state;
    const {
      currentUser, users, selectedUser, projects, categories, logsById, routeParams: { date },
      datesWithLogs, dateTotals, holidays, vacations,
    } = this.props;
    const selectedYear = date ? date.split('-')[0] : _.toString(new Date().getFullYear());
    const selectedDate = date || formatDateToISOString(new Date());
    const selectedUserVacationDates = _.values(vacations).filter(vacation => vacation.id === selectedUser.id)[0].usedVacationDates.map(v => v.date);

    return (
      <div className="timesheets-view view-wrapper">
        <NavContent
          currentUser={currentUser}
          users={users}
          selectedUser={selectedUser}
          selectedYear={selectedYear}
          onNavTodayClick={this.handleNavTodayClick}
          onNavPrevYearClick={this.handleNavPrevYearClick}
          onNavNextYearClick={this.handleNavNextYearClick}
          onSelectedUserChange={this.handleSelectedUserChange}
          isYearCalendar
        />
        <YearCalendarContent
          onOpenAddModal={this.handleOpenAddModal}
          onOpenEditModal={this.handleOpenEditModal}
          selectedYear={selectedYear}
          selectedDate={selectedDate}
          selectedUserId={selectedUser.id}
          logsById={logsById}
          projects={projects}
          onDateSelect={this.handleDateSelect}
          datesWithLogs={datesWithLogs}
          dateTotals={dateTotals}
          holidays={holidays}
          selectedUserVacationDates={selectedUserVacationDates}
          currentUser={currentUser}
        />
        <ReduxModal id={this.getLogModalId()}>
          <CalendarTimeLogModal
            {...modal}
            projects={projects}
            categories={categories}
            onSubmitLogForm={this.handleSubmitLogForm}
            onDeleteLog={this.handleDeleteLog}
            onCloseModal={modal.edit ? this.handleEditModalClose : this.handleAddModalClose}
            currentUser={currentUser}
          />
        </ReduxModal>
      </div>
    );
  }
}

export default (YearCalendar: React$ComponentType<Props>);
