// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import _, { filter, includes } from 'lodash';
import { format, getYear } from 'date-fns';
import NavContent from '../blocks/navigation/NavContent';
import CalendarContent from '../blocks/calendar/CalendarContent';
import ReduxModal from '../blocks/modal/ReduxModal';
import CalendarTimeLogModal from '../blocks/calendar/CalendarTimeLogModal';
import CopyTimeLogModal from '../blocks/calendar/CopyTimeLogModal';
import MiniWeeklyStatistics from '../blocks/calendar/MiniWeeklyStatistics';
import {
  getCalendarRoute, hasCalendarRequestExpired, isUserAdmin, isUserOwner,
} from '../../helpers';
import {
  changeNavWeek, getDatesForWeek, getNavDates, getNavToday, getSelectedWeekDates, getWeekBeginningMon,
} from '../../helpers/dates';
import { LOG_ADD_MODAL, LOG_EDIT_MODAL, LOG_COPY_MODAL } from '../../redux/reducers/modal';
import type {
  Log, Logs, User, Project, Holiday, Router,
} from '../../types';

type State = {
  modal : {
    date: any,
    edit: boolean,
    title: string,
    saveBtnText: string,
    log?: ?Log,
  },
  copyModal : {
    display: boolean,
    log: Object,
  },
  miniStatsDisplayed: boolean,
}

type Props = {
    logs: Logs,
    currentUser: User,
    users: Array<User>,
    daysInWeek: Array<Object>,
    projects: Array<Project>,
    categories: Array<Object>,
    dates: Array<string>,
    selectedUser: User,
    requests: Object,
    routeParams: Object,
    onRemoveLog: Function,
    onAddLog: Function,
    onUpdateLog: Function,
    onLogsChange: Function,
    onSelectedUserChange: Function,
    onChangeDates: Function,
    onAddDraggedLog: Function,
    onRemoveDraggedLog: Function,
    onOpenModal: Function,
    onCloseModal: Function,
    onUpdateModal: Function,
    draggedLog: Log,
    location: Object,
    projectsWeeklyHours: Object,
    holidays: Array<Holiday>,
    vacations: Object,
    router: Router,
}

class Timesheets extends Component<Props, State> {
  state = {
    modal: {
      date: null,
      edit: false,
      saveBtnText: '',
      title: '',
      log: null,
    },
    copyModal: {
      display: false,
      log: {},
    },
    miniStatsDisplayed: false,
  };

  componentDidMount = () => {
    const {
      routeParams: { year, weekNum },
      selectedUser: { id: selectedUserId },
      onLogsChange,
    } = this.props;
    if (weekNum && year) {
      onLogsChange({
        weekNum,
        year,
        selectedUserId,
      });
    }
  };

  componentDidUpdate = (prevProps: Props) => {
    const {
      routeParams: { year, weekNum },
      selectedUser: { id: selectedUserId },
      router: { location },
      onLogsChange,
      onChangeDates,
      requests,
    } = this.props;
    const searchChanged = prevProps.router.location.search !== location.search;
    const weekChanged = prevProps.routeParams.weekNum !== weekNum;
    const yearChanged = prevProps.routeParams.year !== year;
    if (searchChanged || weekChanged || yearChanged) {
      if (!location.search && (!year || !weekNum)) {
        this.handleNavTodayClick();
      } else if (hasCalendarRequestExpired(year, weekNum, selectedUserId, requests) || searchChanged) {
        if (weekNum && year) {
          onLogsChange({
            weekNum,
            year,
            selectedUserId,
          });
        }
      } else {
        onChangeDates(getSelectedWeekDates(year, weekNum));
      }
    }
  };

  getLogModalId = () => {
    const { modal: { edit } } = this.state;

    return edit ? LOG_EDIT_MODAL : LOG_ADD_MODAL;
  };

  handleOpenAddModal = (date: string) => {
    const { onOpenModal } = this.props;
    onOpenModal({ id: LOG_ADD_MODAL });
    this.setState({
      modal: {
        date,
        title: 'Add a log',
        saveBtnText: 'Save new log',
        edit: false,
        log: null,
      },
    });
  };

  handleOpenEditModal = (log: Log, date: string) => {
    const { onOpenModal } = this.props;
    onOpenModal({ id: LOG_EDIT_MODAL });
    this.setState({
      modal: {
        date,
        title: 'Edit log',
        saveBtnText: 'Save',
        edit: true,
        log,
      },
    });
  };

  handleAddModalClose = () => this.handleModalClose(LOG_ADD_MODAL);

  handleEditModalClose = () => this.handleModalClose(LOG_EDIT_MODAL);

  handleModalClose = (id: string) => {
    const { onCloseModal } = this.props;
    onCloseModal({ id });
    this.setState({
      modal: {
        date: null,
        edit: false,
        saveBtnText: '',
        title: '',
        log: null,
      },
    });
  };

  handleCopyModalClose = () => {
    const { onRemoveDraggedLog, onCloseModal } = this.props;
    onCloseModal({ id: LOG_COPY_MODAL });
    this.setState({ copyModal: { display: false, log: {} } });
    onRemoveDraggedLog();
  };

  handleSubmitLogForm = (formData: Object) => {
    const { modal: { date, edit } } = this.state;
    const { onAddLog, onUpdateLog, selectedUser } = this.props;
    this.disableFormControls(edit ? LOG_EDIT_MODAL : LOG_ADD_MODAL);
    formData.date = date;
    formData.user_id = selectedUser.id;
    formData.user = selectedUser;
    if (!edit) {
      onAddLog(formData.project_id, formData).then(this.handleAddModalClose);
    } else {
      onUpdateLog(formData.project_id, formData.id, formData)
        .then(this.handleEditModalClose);
    }
  };

  handleDeleteLog = (log: Log) => {
    const { onRemoveLog } = this.props;
    this.disableFormControls(LOG_EDIT_MODAL);
    onRemoveLog(log.project_id, log.id).then(this.handleEditModalClose);
  };

  disableFormControls = (id: string) => {
    const { onUpdateModal } = this.props;
    onUpdateModal({ id, disableButtons: true });
  };

  handleNavTodayClick = () => {
    const { router: { navigate } } = this.props;
    const { year, week } = getNavToday();
    navigate(getCalendarRoute(year, week));
  };

  handleNavYearCalendarClick = () => {
    const { router } = this.props;
    router.navigate('/year-calendar');
  };

  handleExportEVRClick = () => {
    const { selectedUser } = this.props;
    window.open(`/admin/users/${selectedUser.id}/export_evr`, '_blank');
  };

  handleNavPrevWeekClick = () => this.changeNavWeek(true);

  handleNavNextWeekClick = () => this.changeNavWeek(false);

  changeNavWeek = (subWeek: boolean) => {
    const { dates } = this.props;
    const { navigate } = this.props.router;
    const { year, week } = changeNavWeek(dates, subWeek);
    navigate(getCalendarRoute(year, week));
  };

  handleSelectedUserChange = (user: User) => {
    const {
      routeParams, dates, router, onSelectedUserChange,
    } = this.props;
    const year = routeParams.year || getYear(new Date(dates[0]));
    const weekNum = routeParams.weekNum || getWeekBeginningMon(new Date(dates[0]));
    if (user) {
      onSelectedUserChange(user).then(() => {
        router.navigate(getCalendarRoute(year, weekNum, user.id));
      });
    }
  };

  handleCopyLogByDrop = (projectId: number, data: Object) => {
    const { onAddLog, onOpenModal } = this.props;
    const { copyModal } = this.state;
    const day = format(new Date(data.date), 'EEEE');
    if (['Saturday', 'Sunday'].includes(day)) {
      onOpenModal({ id: LOG_COPY_MODAL });
      this.setState({ copyModal: { ...copyModal, display: true, log: data } });
    } else {
      onAddLog(projectId, data);
    }
  };

  handleToggleStatistics = () => {
    const { miniStatsDisplayed } = this.state;
    if (miniStatsDisplayed) {
      this.setState({ miniStatsDisplayed: false });
    } else {
      this.setState({ miniStatsDisplayed: true });
    }
  };

  handleCloseStatistics = () => {
    this.setState({ miniStatsDisplayed: false });
  };

  getLogIdsForWeekday = (date: string) => {
    const { logs: { logsByWeekday }, selectedUser } = this.props;
    const selectedUserId = selectedUser.id;
    if (logsByWeekday[date]) {
      // const logs = _.compact(logsByWeekday[date]);
      return _.flatMap(logsByWeekday[date].filter(log => _.toInteger(log.userId) === _.toInteger(selectedUserId)), log => log.id);
    }

    return [];
  };

  getLogsForWeek = () => {
    const {
      currentUser, dates, logs, routeParams,
    } = this.props;
    const isAdmin = isUserAdmin(currentUser);
    const isOwner = isUserOwner(currentUser);

    if (isOwner || isAdmin) return [];

    const year = routeParams.year || getYear(new Date(dates[0]));
    const weekNum = routeParams.weekNum || getWeekBeginningMon(new Date(dates[0]));
    const selectedWeekDates = getSelectedWeekDates(year, weekNum);

    return filter(logs.items, (log) => includes(selectedWeekDates, log.date));
  };

  render(): Node {
    const {
      currentUser, selectedUser, users, daysInWeek, projects, categories, logs,
      onAddDraggedLog, draggedLog, onAddLog, projectsWeeklyHours, dates: datesRaw,
      holidays, vacations,
    } = this.props;
    const { modal, copyModal, miniStatsDisplayed } = this.state;
    const projectsHours = projectsWeeklyHours[`${getWeekBeginningMon(new Date(datesRaw[0]))}`];
    const dates = getDatesForWeek(datesRaw);
    const navDates = getNavDates(dates.map(({ date }) => new Date(date)));
    const logsForWeek = this.getLogsForWeek();
    const selectedUserVacationDates = _.values(vacations).filter(({ id }) => id === selectedUser.id)[0].usedVacationDates.map(({ date }) => date);

    return (
      <div className="timesheets-view view-wrapper">
        <NavContent
          navDates={navDates}
          currentUser={currentUser}
          selectedUser={selectedUser}
          users={users}
          onNavTodayClick={this.handleNavTodayClick}
          onNavPrevWeekClick={this.handleNavPrevWeekClick}
          onNavNextWeekClick={this.handleNavNextWeekClick}
          onSelectedUserChange={this.handleSelectedUserChange}
          onToggleStatistics={this.handleToggleStatistics}
          onNavYearCalendarClick={this.handleNavYearCalendarClick}
          onExportEVRClick={this.handleExportEVRClick}
          isCalendar
        />
        {miniStatsDisplayed && (
          <MiniWeeklyStatistics
            onCloseClick={this.handleCloseStatistics}
            projects={projects}
            projectsHours={projectsHours}
            logs={logsForWeek}
            currentUser={currentUser}
          />
        )}
        <CalendarContent
          onOpenEditModal={this.handleOpenEditModal}
          onOpenAddModal={this.handleOpenAddModal}
          getLogIdsForWeekday={this.getLogIdsForWeekday}
          dates={dates}
          selectedUserId={selectedUser.id}
          logsById={logs.logsById}
          daysInWeek={daysInWeek}
          projects={projects}
          onAddLog={this.handleCopyLogByDrop}
          onAddDraggedLog={onAddDraggedLog}
          draggedLog={draggedLog}
          weekendDrop={copyModal.display}
          holidays={holidays}
          selectedUserVacationDates={selectedUserVacationDates}
          currentUser={currentUser}
        />
        <ReduxModal id={this.getLogModalId()}>
          <CalendarTimeLogModal
            {...modal}
            projects={projects}
            categories={categories}
            onSubmitLogForm={this.handleSubmitLogForm}
            onDeleteLog={this.handleDeleteLog}
            onCloseModal={modal.edit ? this.handleEditModalClose : this.handleAddModalClose}
            currentUser={currentUser}
          />
        </ReduxModal>
        <ReduxModal id={LOG_COPY_MODAL}>
          <CopyTimeLogModal
            log={copyModal.log}
            onCloseModal={this.handleCopyModalClose}
            onSubmit={onAddLog}
          />
        </ReduxModal>
      </div>
    );
  }
}

export default (Timesheets: React$ComponentType<Props>);
