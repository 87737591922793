import React from 'react';

type Props = {
  activeFilter: string,
  onFilterChange: string,
};

const FilterSection = (props: Props): Node => {
  const { activeFilter, onFilterChange } = props;

  const filters = [
    'All',
    'Project',
    'Category',
    'Worked hours',
    'Task ID',
    'Description',
    'Overtime',
  ];

  return (
    <div className="filter-container">
      <div className="filter-tabs">
        {filters.map((filter) => (
          <button
            key={filter}
            className={`filter-button ${activeFilter === filter ? 'active' : ''}`}
            onClick={() => onFilterChange(filter)}
          >
            {filter}
          </button>
        ))}
      </div>
    </div>
  );
};

export default FilterSection;
