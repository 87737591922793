// @flow
import React, { useState } from 'react';
import type { Node } from 'react';
import type { Change } from '../../../types';
import FilterSection from './FilterSection';
// $FlowFixMe
import Arrow from '../../../../assets/images/arrow_right.svg';

type Props = {
  changes?: Array<Change>,
};

const ModalChanges = (props: Props): Node => {
  const { changes } = props;

  const [activeFilter, setActiveFilter] = useState('All');

  const filteredChanges: Array<Change> | void = changes?.filter((change) => {
    if (activeFilter === 'All') {
      return true;
    }

    return change.change_type.toLowerCase() === activeFilter.toLowerCase();
  });

  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(today.getDate() - 1);

  const getDateLabel = (versionDate: string): string => {
    const formatDate = (date: Date): string => {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().slice(-2);

      return `${day}.${month}.${year}.`;
    };

    if (versionDate === formatDate(today)) return 'Today';
    if (versionDate === formatDate(yesterday)) return 'Yesterday';

    return versionDate;
  };

  const groupedChanges = filteredChanges?.reduce((acc, change) => {
    const label = getDateLabel(change.version_date);
    if (!acc[label]) {
      acc[label] = [];
    }
    acc[label].push(change);

    return acc;
  }, {});

  return (
    <>
      <FilterSection
        activeFilter={activeFilter}
        onFilterChange={setActiveFilter}
      />
      <div className="change-table-container">
        {Object.entries(groupedChanges ?? {}).map(([section, sectionChanges]) => {
          const changesArray: Array<Change> = (sectionChanges: any);

          return (
            <div key={section} className="changelog-section">
              <div className="section-label">{section}</div>
              {changesArray.map((change) => (
                <div
                  key={`${change.id}${change.change_type}`}
                  className="changelog-content"
                >
                  {change.change_type === 'created_at' ? (
                    <>
                      <div className="info">
                        <span className="change-owner float-left">
                          {change.changed_by}
                        </span>
                        <span className="change-date float-right">{`${change.version_date} | ${change.version_time}`}</span>
                      </div>
                      <div className="data">
                        <span className="change-type-first">Log created.</span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="info">
                        <span className="change-owner float-left">
                          {change.changed_by}
                        </span>
                        <span className="change-date float-right">{`${change.version_date} | ${change.version_time}`}</span>
                      </div>
                      <div
                        className={`data ${
                          change.change_type === 'description' ? 'description' : ''
                        }`}
                      >
                        <div className="change-type">
                          <span className="change-type-first float-left">
                            {`${change.change_type}`}
                          </span>
                          {`${change.previous === '' ? 'addition' : 'change'}`}.
                        </div>
                        {change.change_type === 'description' ? (
                          <div className="description-container">
                            <div className="description-previous">
                              {change.previous}
                            </div>
                            <div className="description-current">{change.current}</div>
                          </div>
                        ) : (
                          <div className="change-data float-right">
                            <span className="change-previous">{change.previous}</span>
                            <img
                              src={Arrow}
                              alt="arrow-right"
                              className="arrow-right-img"
                            />
                            <span className="change-current">{change.current}</span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ModalChanges;
