// @flow
/* eslint camelcase: ["off"] */
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import Checkbox from '../../elements/Checkbox';
import Select from '../../elements/Select';
import Input from '../../elements/Input';
import Textarea from '../../elements/Textarea';
import ModalHeader from '../modal/ModalHeader';
import ModalBody from '../modal/ModalBody';
import ModalFooter from '../modal/ModalFooter';
import ModalControls from '../modal/ModalControls';
import ModalChanges from '../modal/ModalChanges';
import type { Project, Log, User } from '../../../types';

type State = {
  form: {
    project_id: number | string,
    category: number | string,
    time_spent: number | string,
    description: string,
    overtime: boolean,
    task_id: string | null,
    away: boolean,
  },
  showChanges: boolean,
}

type Props = {
  disableButtons?: boolean,
  date: any,
  edit: boolean,
  title: string,
  saveBtnText: string,
  log?: ?Log,
  projects: Array<Project>,
  categories: Array<Project>,
  onSubmitLogForm: Function,
  onDeleteLog: Function,
  onCloseModal: Function,
  currentUser: User,
}

class CalendarTimeLogModal extends Component<Props, State> {
  static defaultProps: Object = {
    disableButtons: false,
  };

  state: State = {
    form: {
      project_id: '',
      category: '',
      time_spent: '',
      description: '',
      overtime: false,
      task_id: '',
      away: false,
    },
    showChanges: false,
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    const {
      edit, projects, log, date, currentUser,
    } = this.props;
    const { form } = this.state;
    if (prevProps.edit !== edit || prevProps.date !== date) {
      if (edit) {
        const {
        // $FlowFixMe
          project_id, category, time_spent, description, overtime, task_id,
        } = log;
        this.setState({
          form: {
            project_id,
            category,
            time_spent,
            description,
            overtime,
            task_id,
            away: _.find(projects, ['id', project_id])?.client_name === 'Away',
          },
          showChanges: false,
        });
      } else {
        this.setState({
          form: {
            project_id: this.getPreselectedProjectId(currentUser, projects),
            category: '',
            time_spent: '',
            description: '',
            overtime: false,
            task_id: '',
            away: false,
          },
          showChanges: false,
        });
      }
    }
    if (prevState.form.project_id !== form.project_id && prevState.form.time_spent === form.time_spent) {
      this.setState({ form: { ...form, task_id: '' } });
    }
  };

  getDefaultProjectSelection = (currentUser: User, projects: Array<Project>) => {
    const filteredProjects = _.filter(projects, (p) => p.active && p.client_name !== 'Away');

    return _.uniqBy(_.concat(_.filter(currentUser.projects, 'active'), filteredProjects), 'id');
  };

  getPreselectedProjectId = (currentUser: User, projects: Array<Project>) => {
    if (currentUser.role === 'member') {
      return _.get(_.filter(currentUser.projects, 'active'), [0, 'id']);
    }

    return _.get(this.getDefaultProjectSelection(currentUser, projects), [0, 'id']);
  };

  getProject = (projects: Array<Project>, projectId: string|number) => _.find(projects, ['id', projectId]);

  getFormValueForState = (field: string, value: any): Object => {
    const { form } = this.state;

    return {
      form: {
        ...form,
        [field]: value,
      },
    };
  };

  handleAwayChange = (event: Object) => {
    const firstAwayProject = this.props.projects.filter(p => p.client_name === 'Away');
    this.setState({ form: { ...this.state.form, project_id: firstAwayProject[0].id, away: event.target.checked } });
  };

  handleProjectSelect = (event: Object) => {
    this.setState(this.getFormValueForState('project_id', _.toInteger(event.target.value)));
  };

  handleCategorySelect = (event: Object) => {
    this.setState(this.getFormValueForState('category', event.target.value));
  };

  handleHoursChange = (event: Object) => {
    this.setState(this.getFormValueForState('time_spent', event.target.value));
  };

  handleTaskChange = (event: Object) => {
    this.setState(this.getFormValueForState('task_id', event.target.value));
  };

  handleDescriptionChange = (event: Object) => {
    this.setState(this.getFormValueForState('description', event.target.value));
  };

  handleOvertimeChange = (event: Object) => {
    this.setState(this.getFormValueForState('overtime', event.target.checked));
  };

  handleDeleteClick = () => {
    const { onDeleteLog, log } = this.props;
    onDeleteLog(log);
  };

  handleSubmit = () => {
    const { onSubmitLogForm, log, edit } = this.props;
    const { form } = this.state;
    const timeSpent = _.round(form.time_spent, 1);
    const formData = { ...form, time_spent: timeSpent };
    if (edit) {
      if (!this.isProjectCategorizable()) formData.category = 'other';
      /* $FlowFixMe $ */
      formData.id = log.id;
    }
    onSubmitLogForm(formData);
  };

  handleEditlogClick = () => {
    this.setState({ showChanges: false });
  };

  handleChangelogClick = () => {
    this.setState({ showChanges: true });
  };

  isFormValid = () => {
    const {
      form: {
        project_id, time_spent, description, task_id, category,
      },
    } = this.state;
    const { projects } = this.props;
    const project = _.find(projects, ['id', project_id]);

    return project_id !== '' && time_spent !== '' && description !== ''
      && _.isFinite(parseFloat(time_spent)) && _.toInteger(time_spent) >= 0
      && (!project.task_id_required || task_id !== '')
      && (!project.categorize_logs || category !== '');
  };

  isProjectCategorizable = () => {
    const { form } = this.state;
    const { project_id } = form;
    const { projects } = this.props;

    if (!project_id) {
      return false;
    }

    return _.find(projects, pr => pr.id === _.toInteger(project_id)).categorize_logs;
  };

  isProjectActive = () => {
    const { form } = this.state;
    const { project_id } = form;
    const { projects } = this.props;
    if (!project_id) return false;

    return _.find(projects, pr => pr.id === _.toInteger(project_id)).active;
  };

  render(): Node {
    const { form, showChanges } = this.state;
    const {
      projects,
      categories,
      saveBtnText,
      title,
      edit,
      disableButtons,
      onCloseModal,
      currentUser,
      log,
    } = this.props;

    let filteredProjects = [];
    if (form.away) {
      filteredProjects = _.filter(projects, ['client_name', 'Away']);
    } else if (currentUser.role === 'member') {
      filteredProjects = _.filter(currentUser.projects, 'active');
    } else {
      filteredProjects = this.getDefaultProjectSelection(currentUser, projects);
    }

    const taskIdLabel = 'Task ID' + (_.get(this.getProject(projects, form.project_id), 'task_id_required') ? ' *' : '');

    const showChangelogButton = log && log?.changes.length > 1 && (currentUser.isUserAdmin || currentUser.isUserOwner);

    return (
      <>
        <ModalHeader
          title={title}
          onCloseClick={onCloseModal}
          onEditlogClick={this.handleEditlogClick}
          onChangelogClick={this.handleChangelogClick}
          showChangelogButton={showChangelogButton}
          showChanges={showChanges}
        />
        { !showChanges
          ? (
            <ModalBody>
              <form>
                <div className="form-check">
                  <Checkbox
                    name="away"
                    label="Away"
                    id="away-checkbox"
                    checked={form.away}
                    onChange={this.handleAwayChange}
                  />
                </div>

                {!(edit && !this.isProjectActive()) ? (
                  <Select
                    id="log-title"
                    label="Select a project"
                    labelClass="col-form-label"
                    selectClass="form-control js-title"
                    value={form.project_id}
                    items={filteredProjects}
                    onSelectChange={this.handleProjectSelect}
                  />
                ) : null}

                {this.isProjectCategorizable() ? (
                  <Select
                    id="category"
                    label="Select a category"
                    labelClass="col-form-label"
                    selectClass="form-control js-title"
                    value={form.category}
                    items={categories}
                    onSelectChange={this.handleCategorySelect}
                    includeChooseOption
                  />
                ) : null}

                <Input
                  id="hours"
                  type="number"
                  name="hours"
                  label="Worked hours *"
                  required
                  value={form.time_spent}
                  onChange={this.handleHoursChange}
                />
                <Input
                  id="task_id"
                  type="text"
                  name="task_id"
                  label={taskIdLabel}
                  value={form.task_id || ''}
                  onChange={this.handleTaskChange}
                />
                <Textarea
                  id="description"
                  name="description"
                  label="Work description *"
                  required
                  value={form.description}
                  onChange={this.handleDescriptionChange}
                />
                <div className="form-check">
                  <Checkbox
                    name="overtime"
                    label="Overtime"
                    id="add-Overtime"
                    checked={form.overtime}
                    onChange={this.handleOvertimeChange}
                  />
                  <i className="fa fa-clock-o mr-1" title="overtime" />
                </div>
              </form>
            </ModalBody>
          )
          : (
            <ModalChanges
              showChanges={showChanges}
              changes={log?.changes}
            />
          )
        }
        <ModalFooter>
          <ModalControls
            showEditButton={edit}
            disableButtons={disableButtons || false}
            isFormValid={!this.isFormValid()}
            onSubmitButtonClick={this.handleSubmit}
            onDeleteButtonClick={this.handleDeleteClick}
            onCloseButtonClick={onCloseModal}
            submitButtonText={saveBtnText}
            deleteButtonText="Delete log"
          />
        </ModalFooter>
      </>
    );
  }
}

export default (CalendarTimeLogModal: React$ComponentType<Props>);
