// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import DayColumn from './DayColumn';
import DayHeading from './DayHeading';
import type { CalendarContentProps } from '../../../types';

type State = {
  dragTargetDate: string,
};

class CalendarContentBig extends Component<CalendarContentProps, State> {
  state: State = {
    dragTargetDate: '',
  };

  render(): Node {
    const {
      onOpenAddModal,
      onOpenEditModal,
      dates,
      logsById,
      projects,
      getLogIdsForWeekday,
      onAddDraggedLog,
      draggedLog,
      onAddLog,
      weekendDrop,
      holidays,
      selectedUserVacationDates,
      currentUser,
    } = this.props;
    const { dragTargetDate } = this.state;

    return (
      <div className="container-flex big-container">
        <div className="device-big device-big-flex">
          <div className="row calendar-day no-gutters">
            {dates.map(date => (
              <DayHeading
                day={date.day}
                month={date.month}
                dayName={date.dayName}
                key={date.dayName}
                isToday={date.isToday}
              />
            ))}
          </div>
          <div className="calendar-main-content">
            <div className="row calendar-bottom no-gutters">
              {dates.map(day => (
                <DayColumn
                  logIds={getLogIdsForWeekday(day.date)}
                  logsById={logsById}
                  onOpenEditModal={onOpenEditModal}
                  onOpenAddModal={onOpenAddModal}
                  onAddDraggedLog={onAddDraggedLog}
                  onAddLog={onAddLog}
                  draggedLog={draggedLog}
                  projects={projects}
                  day={day}
                  key={day.date}
                  onDragEntered={() => this.setState({ dragTargetDate: day.date })}
                  onDragEnded={() => this.setState({ dragTargetDate: weekendDrop ? dragTargetDate : '' })}
                  dragTargetDate={dragTargetDate}
                  holidays={holidays}
                  selectedUserVacationDates={selectedUserVacationDates}
                  currentUser={currentUser}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default (CalendarContentBig: React$ComponentType<CalendarContentProps>);
