// @flow
import React, { useState } from 'react';
import Select from 'react-select';
import type { Node } from 'react';
import {
  find, groupBy, map, omit,
} from 'lodash';
import type { User } from '../../types';

type Props = {
  users: Array<User>,
  selectedUser: User,
  onSelectedUserChange: (user: User) => void,
}

const UserSelect = (props: Props): Node => {
  const { users, selectedUser, onSelectedUserChange } = props;
  const [showInactive, setShowInactive] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const groupedUsers = omit(groupBy(users, (user) => {
    if (user.student) return 'student';
    if (!user.active) return 'inactive';

    return user.role;
  }), 'client');

  const sortObjectByRef = () => {
    const sortedObject = {};
    const desiredOrder = ['owner', 'admin', 'member', 'student', 'inactive'];

    for (const key of desiredOrder) {
      if (key in groupedUsers) sortedObject[key] = groupedUsers[key];
    }

    return sortedObject;
  };

  const sortedGroups = sortObjectByRef();

  const selectableUsers = map(sortedGroups, (userGroup, key) => {
    const isInactiveGroup = key === 'inactive';

    if (isInactiveGroup) {
      return {
        label:
  <span onClick={() => setShowInactive(!showInactive)} style={{ cursor: 'pointer' }}>
    {showInactive ? '▼' : '►'} inactive
  </span>,
        options: isInactiveGroup && showInactive
          ? map(userGroup, (user: User) => ({
            label: `${user.name} ${user.surname}`,
            value: user,
          }))
          : [''],
      };
    }

    return {
      label: key,
      options: map(userGroup, (user: User) => ({
        label: `${user.name} ${user.surname}`,
        value: user,
      })),
    };
  });

  const handleGroupClick = (option) => {
    if (option.value) {
      onSelectedUserChange(option.value);
    }
  };

  return (
    <Select
      id="admin-select"
      className="admin-select"
      classNamePrefix="select"
      value={{
        label: `${selectedUser.name} ${selectedUser.surname}`,
        value: find(users, ['id', selectedUser.id]),
      }}
      options={selectableUsers}
      onChange={handleGroupClick}
      isSearchable
      menuIsOpen={menuIsOpen}
      onMenuOpen={() => setMenuIsOpen(true)}
      onMenuClose={() => setMenuIsOpen(false)}
    />
  );
};

export default UserSelect;
