// @flow
import React from 'react';
import type { Node } from 'react';
import UserSelect from '../../elements/UserSelect';
import NavDropdown from './NavDropdown';
import NavDatesSelector from './NavDatesSelector';
import NavYearSelector from './NavYearSelector';
import NavToday from '../../elements/NavToday';
import { isUserAdmin, isUserOwner } from '../../../helpers';
import type { NavProps } from '../../../types';

const CalendarNavBigScreen = (props: NavProps): Node => {
  const {
    navDates,
    isCalendar,
    isYearCalendar,
    currentUser,
    selectedUser,
    selectedYear,
    users,
    onNavTodayClick,
    onNavPrevWeekClick,
    onNavNextWeekClick,
    onSelectedUserChange,
    onToggleStatistics,
    onNavPrevYearClick,
    onNavNextYearClick,
    onNavYearCalendarClick,
    onExportEVRClick,
  } = props;
  const isAdmin = isUserAdmin(currentUser);
  const isOwner = isUserOwner(currentUser);

  return (
    <div className="device-big">
      <div className="row">
        <nav className="navbar navbar-default nav-flex">
          <div className="container-fluid">
            <div className="today-btn-wrapper">
              <NavToday isCalendar onNavTodayClick={onNavTodayClick} />
              {!isYearCalendar && (
                <button
                  type="button"
                  className="btn mini-stats-btn js-curr-week"
                  onClick={onToggleStatistics}
                >
                  Mini Stats
                </button>
              )}
              {!isYearCalendar && (
                <button
                  type="button"
                  className="btn mini-stats-btn js-curr-week"
                  onClick={onNavYearCalendarClick}
                >
                  Year Calendar
                </button>
              )}
              {isYearCalendar && (
                <button
                  type="button"
                  className="btn mini-stats-btn js-curr-week"
                  onClick={() => onNavTodayClick('to-week-calendar')}
                >
                  Week Calendar
                </button>
              )}
            </div>
            <NavDatesSelector
              isCalendar={isCalendar || false}
              navDates={navDates}
              onNavPrevWeekClick={onNavPrevWeekClick}
              onNavNextWeekClick={onNavNextWeekClick}
            />
            <NavYearSelector
              selectedYear={selectedYear || new Date().getFullYear()}
              isYearCalendar={isYearCalendar || false}
              onNavPrevYearClick={onNavPrevYearClick}
              onNavNextYearClick={onNavNextYearClick}
            />
            <div className="nav-right">
              {(isAdmin || isOwner) && (
                <button
                  type="button"
                  className="btn export-evr-btn js-curr-week"
                  onClick={onExportEVRClick}
                >
                  Export ERV
                </button>
              )}
              {isAdmin || isOwner ? (
                <UserSelect
                  users={users}
                  selectedUser={selectedUser}
                  onSelectedUserChange={onSelectedUserChange}
                />
              ) : null}
              <NavDropdown isAdmin={isAdmin} isOwner={isOwner} />
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default CalendarNavBigScreen;
