// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import CalendarNavBigScreen from './CalendarNavBigScreen';
import CalendarNavSmallScreen from './CalendarNavSmallScreen';
import type { NavProps } from '../../../types';

const dummyFunc = () => {};

class NavContent extends Component<NavProps> {
  static defaultProps = {
    isCalendar: false,
    navDates: {},
    onNavNextWeekClick: dummyFunc,
    onNavPrevWeekClick: dummyFunc,
    isYearCalendar: false,
    selectedYear: 0,
    onNavPrevYearClick: dummyFunc,
    onNavNextYearClick: dummyFunc,
    onNavYearCalendarClick: dummyFunc,
    onToggleStatistics: dummyFunc,
    onExportEVRClick: dummyFunc,
  };

  render(): Node {
    return (
      <>
        <CalendarNavBigScreen {...this.props} />
        <CalendarNavSmallScreen {...this.props} />
      </>
    );
  }
}

export default (NavContent: React$ComponentType<NavProps>);
