// @flow
import React from 'react';
import type { Node } from 'react';

type Props = {
  title: string,
  onCloseClick: Function,
  onEditlogClick?: Function,
  onChangelogClick?: Function,
  showChangelogButton?: boolean | any,
  showChanges?: boolean,
}

const ModalHeader = (props: Props): Node => {
  const {
    title, onCloseClick, onEditlogClick, onChangelogClick, showChangelogButton, showChanges,
  } = props;

  return (
    <div className={`modal-header ${
      showChangelogButton ? 'changelog' : ''
    }`}
    >
      { !showChangelogButton && <h5 className="modal-title">{title}</h5> }
      { showChangelogButton
        && (
        <div className="btn-container">
          <h5 className="modal-title-info">Log information</h5>
          <button
            type="button"
            className={`modal-title-btn ${
              showChanges ? '' : 'active'
            }`}
            onClick={onEditlogClick}
          >
            {title}
          </button>
          <button
            type="button"
            className={`modal-title-btn ${
              showChanges ? 'active' : ''
            }`}
            onClick={onChangelogClick}
          >
            History
          </button>
        </div>
        )
      }
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={onCloseClick}
      >
        <span aria-hidden="true">{'\u00d7'}</span>
      </button>
    </div>
  );
};

export default ModalHeader;
