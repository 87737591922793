// @flow
import React, { Component } from 'react';
import type { Node } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import DayColumnYear from '../calendar/DayColumnYear';
import Calendar from '../management/Calendar';
import type { Project, Holiday, User } from '../../../types';

type Props = {
  onOpenAddModal: Function,
  onOpenEditModal: Function,
  onDateSelect: Function,
  logsById: Object,
  datesWithLogs: Array<string>,
  dateTotals: {[date: string]: number},
  selectedYear: number,
  selectedDate: string,
  projects: Array<Project>,
  selectedUserId: number,
  holidays: Array<Holiday>,
  selectedUserVacationDates: Array<string>,
  currentUser?: User,
}

type State = {
  isSmallScreen: boolean,
}

const mediaQuery = window.matchMedia('(max-width: 991px)');

class YearCalendarContent extends Component<Props, State> {
  state = {
    isSmallScreen: false,
  };

  handleResize = () => {
    this.setState({ isSmallScreen: mediaQuery.matches });
  };

  componentDidMount = () => {
    mediaQuery.addEventListener('change', this.handleResize);
  };

  componentWillUnmount = () => {
    mediaQuery.removeEventListener('change', this.handleResize);
  };

  render(): Node {
    const {
      onOpenAddModal, onOpenEditModal, onDateSelect, selectedUserId,
      logsById, selectedYear, selectedDate, projects, datesWithLogs,
      dateTotals, holidays, selectedUserVacationDates, currentUser,
    } = this.props;
    const { isSmallScreen } = this.state;
    const logIds = _.keys(logsById).filter(logId => logsById[logId].date === selectedDate && logsById[logId].user.id === selectedUserId);
    const markedDates = datesWithLogs ? _.map(datesWithLogs, d => ({ date: d, hoursMissing: _.get(dateTotals, d, 0) < 8 })) : [];
    selectedUserVacationDates.forEach(vd => {
      markedDates.push({
        date: vd, isVacation: true, hoursMissing: false, isHoliday: false, locked: false,
      });
    });
    holidays.forEach(h => {
      markedDates.push({
        date: h.date, isHoliday: true, hoursMissing: false, isVacation: false, locked: false,
      });
    });
    markedDates.push({
      date: selectedDate, locked: true, hoursMissing: false, isHoliday: false, isVacation: false,
    });

    return (
      <div className="pt-3 container h-100">
        <div className={classNames('year-calendar-container h-100', { column: isSmallScreen, row: !isSmallScreen })}>
          <DayColumnYear
            className={classNames({ 'col-12': isSmallScreen, 'col-2': !isSmallScreen })}
            logIds={logIds}
            logsById={logsById}
            onOpenAddModal={onOpenAddModal}
            onOpenEditModal={onOpenEditModal}
            projects={projects}
            selectedDate={selectedDate}
            holidays={holidays}
            selectedUserVacationDates={selectedUserVacationDates}
            currentUser={currentUser}
          />
          <div className={classNames({ 'col-12': isSmallScreen, 'col-9': !isSmallScreen })}>
            <Calendar
              selectedYear={selectedYear}
              selectedDate={selectedDate}
              dates={markedDates}
              isYearCalendar
              onOpenAddModal={onOpenAddModal}
              onOpenEditModal={onOpenEditModal}
              onDateSelect={onDateSelect}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default (YearCalendarContent: React$ComponentType<Props>);
